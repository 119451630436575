"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");

var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));

var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));

var _splice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/splice"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _values = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/values"));

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _lodash = _interopRequireDefault(require("lodash"));

var TextFormatting = _interopRequireWildcard(require("@/helpers/TextFormatting"));

var settingTools = _interopRequireWildcard(require("@/libs/settingTools"));

var _autocompleteCommands = _interopRequireDefault(require("@/res/autocompleteCommands"));

var _GlobalApi = _interopRequireDefault(require("@/libs/GlobalApi"));

var _AutoComplete = _interopRequireDefault(require("./AutoComplete"));

var _TextStyle = _interopRequireDefault(require("./inputtools/TextStyle"));

var _Emoji = _interopRequireDefault(require("./inputtools/Emoji"));

var _SelfUser = _interopRequireDefault(require("./SelfUser"));

var _AwayStatusIndicator = _interopRequireDefault(require("./AwayStatusIndicator"));

var _TypingUsersList = _interopRequireDefault(require("./TypingUsersList"));

function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var _default = {
  components: {
    AutoComplete: _AutoComplete.default,
    AwayStatusIndicator: _AwayStatusIndicator.default,
    SelfUser: _SelfUser.default,
    TypingUsersList: _TypingUsersList.default
  },
  props: ['network', 'buffer', 'sidebarState'],
  data: function data() {
    return {
      self: this,
      selfuser_open: false,
      autocomplete_open: false,
      autocomplete_items: [],
      autocomplete_filter: '',
      // Not filtering through the autocomplete list means that the entire word is put
      // in place when cycling through items. Just as with traditional IRC clients when
      // tabbing through nicks.
      // When filtering through the list, we keep typing more of the word we want as the
      // autocomplete list filters its results to show us the relevant items, not replacing
      // the current word until we select an item.
      autocomplete_filtering: true,
      active_tool: null,
      active_tool_props: {},
      pluginUiElements: _GlobalApi.default.singleton().controlInputPlugins,
      showPlugins: false,
      current_input_value: '',
      has_focus: false,
      keep_focus: false
    };
  },
  computed: {
    currentNick: function currentNick() {
      var activeNetwork = this.$state.getActiveNetwork();
      return activeNetwork ? activeNetwork.nick : '';
    },
    networkState: function networkState() {
      var activeNetwork = this.$state.getActiveNetwork();
      return activeNetwork ? activeNetwork.state : '';
    },
    shouldShowSendButton: function shouldShowSendButton() {
      return this.$state.ui.is_touch || this.$state.setting('showSendButton');
    },
    shouldShowEmojiPicker: function shouldShowEmojiPicker() {
      return this.$state.setting('showEmojiPicker') && !this.$state.ui.is_touch;
    },
    shouldShowColorPicker: function shouldShowColorPicker() {
      return this.$state.setting('showColorPicker');
    },
    shouldShowTools: function shouldShowTools() {
      if (this.pluginUiElements.length || this.shouldShowEmojiPicker || this.shouldShowColorPicker) {
        return true;
      }

      return false;
    },
    shouldShowToolsInline: function shouldShowToolsInline() {
      var toolCount = this.pluginUiElements.length;

      if (this.shouldShowEmojiPicker) {
        toolCount++;
      }

      if (this.shouldShowColorPicker) {
        toolCount++;
      }

      if (toolCount === 1) {
        // No point showing a menu button to replace one item
        return true;
      } // Button size (36px)
      // Total buttons width < 1/5 screen width


      return 36 * toolCount < this.$state.ui.app_width / 5;
    },
    history: function history() {
      if (this.$state.setting('buffers.shared_input')) {
        return this.$state.ui.input_history;
      }

      return this.buffer.input_history;
    },
    history_pos: {
      get: function get() {
        if (this.$state.setting('buffers.shared_input')) {
          return this.$state.ui.input_history_pos;
        }

        return this.buffer.input_history_pos;
      },
      set: function set(newVal) {
        if (this.$state.setting('buffers.shared_input')) {
          this.$state.ui.input_history_pos = newVal;
        } else {
          this.buffer.input_history_pos = newVal;
        }
      }
    }
  },
  watch: {
    history_pos: function history_pos(newVal) {
      var val = this.history[this.history_pos];
      this.$refs.input.setValue(val || '');
    },
    buffer: function buffer() {
      if (!this.$state.setting('buffers.shared_input')) {
        this.inputRestore();
      }

      this.autocomplete_open = false;
    }
  },
  created: function created() {
    var _this = this;

    this.typingTimer = null;
    this.lastTypingTime = 0;
    this.listen(this.$state, 'document.keydown', function (ev) {
      // No input box currently? Nothing to shift focus to
      if (!_this.$refs.input) {
        return;
      } // If we're copying text, don't shift focus


      if (ev.ctrlKey || ev.altKey || ev.metaKey) {
        return;
      } // shift key on its own, don't shift focus we handle this below


      if (ev.keyCode === 16) {
        return;
      } // Firefox 66.0.3 on linux isn't consistently setting ev.ctrlKey === true when only
      // the control key is pressed so add a specific check for this
      // TODO: Remove this check once ff 66.0.3 is no longer around


      if (ev.keyCode === 17) {
        return;
      } // If we are using shift and arrow keys, don't shift focus
      // this allows users to adjust text selection


      var arrowKeyCodes = [37, 38, 39, 40];

      if (ev.shiftKey && arrowKeyCodes.indexOf(ev.keyCode) !== -1) {
        return;
      } // If we're typing into an input box somewhere, ignore


      var elements = ['input', 'select', 'textarea', 'button', 'datalist', 'keygen'];
      var doNotRefocus = elements.indexOf(ev.target.tagName.toLowerCase()) > -1 || ev.target.getAttribute('contenteditable');

      if (doNotRefocus) {
        return;
      }

      _this.$refs.input.focus();
    });
    this.listen(this.$state, 'input.insertnick', function (nick) {
      if (!_this.$refs.input) {
        return;
      }

      var val = nick;

      if (_this.current_input_value === '') {
        val += ': ';
      } else {
        val += ' ';
      }

      _this.$refs.input.insertText(val);
    });
    this.listen(this.$state, 'input.tool', function (toolComponent) {
      _this.toggleInputTool(toolComponent);
    });
  },
  mounted: function mounted() {
    this.inputRestore();
  },
  methods: {
    inputUpdate: function inputUpdate(val) {
      this.current_input_value = val;

      if (this.$state.setting('buffers.shared_input')) {
        this.$state.ui.current_input = val;
      } else {
        this.buffer.current_input = val;
      }

      this.maybeHidePlugins();
    },
    inputRestore: function inputRestore() {
      var currentInput = this.$state.setting('buffers.shared_input') ? this.$state.ui.current_input : this.buffer.current_input;
      this.$refs.input.reset(currentInput, this.keep_focus);
      this.$refs.input.selectionToEnd();
    },
    toggleSelfUser: function toggleSelfUser() {
      if (this.networkState === 'connected') {
        this.selfuser_open = !this.selfuser_open;
      }
    },
    maybeHidePlugins: function maybeHidePlugins() {
      // Save some space if we're typing on a small screen
      if (this.$state.ui.app_width < 500) {
        this.showPlugins = false;
      }
    },
    onToolClickTextStyle: function onToolClickTextStyle() {
      this.toggleInputTool(_TextStyle.default);
    },
    onToolClickEmoji: function onToolClickEmoji() {
      this.toggleInputTool(_Emoji.default);
    },
    closeToolsPlugins: function closeToolsPlugins() {
      this.showPlugins = false;
      this.closeInputTool();
    },
    closeInputTool: function closeInputTool() {
      this.active_tool = null;
    },
    toggleInputTool: function toggleInputTool(tool) {
      if (!tool || this.active_tool === tool) {
        this.active_tool = null;
      } else {
        this.active_tool_props = {
          buffer: this.buffer,
          ircinput: this.$refs.input
        };
        this.active_tool = tool;
      }
    },
    toggleBold: function toggleBold() {
      this.$refs.input.toggleBold();
    },
    toggleItalic: function toggleItalic() {
      this.$refs.input.toggleItalic();
    },
    toggleUnderline: function toggleUnderline() {
      this.$refs.input.toggleUnderline();
    },
    onAutocompleteCancel: function onAutocompleteCancel() {
      this.autocomplete_open = false;
    },
    onAutocompleteTemp: function onAutocompleteTemp(selectedValue, selectedItem) {
      if (!this.autocomplete_filtering) {
        this.$refs.input.setCurrentWord(selectedValue);
      }
    },
    onAutocompleteSelected: function onAutocompleteSelected(selectedValue, selectedItem) {
      var word = selectedValue;

      if (word.length > 0) {
        this.$refs.input.setCurrentWord(word);
      }

      this.autocomplete_open = false;
    },
    inputKeyDown: function inputKeyDown(event) {
      var _this2 = this;

      var meta = false;

      if (navigator.appVersion.indexOf('Mac') !== -1) {
        meta = event.metaKey;
      } else {
        meta = event.ctrlKey;
      } // If autocomplete has handled the event, don't also handle it here


      if (this.autocomplete_open && this.$refs.autocomplete.handleOnKeyDown(event)) {
        return;
      } // When not filtering, select the current autocomplete item so that we can type any
      // character directly after a nick


      if (this.autocomplete_open && !this.autocomplete_filtering) {
        this.$refs.autocomplete.selectCurrentItem();
      }

      if (event.keyCode === 13 && (event.altKey && !event.shiftKey && !event.metaKey && !event.ctrlKey || event.shiftKey && !event.altKey && !event.metaKey && !event.ctrlKey)) {
        // Add new line when shift+enter or alt+enter is pressed
        event.preventDefault();
        this.$refs.input.insertText('\n');
      } else if (event.keyCode === 13) {
        // Send message when enter is pressed
        event.preventDefault();
        this.submitForm();
      } else if (event.keyCode === 32) {
        // Hitting space after just typing an ascii emoji will get it replaced with
        // its image
        if (this.$state.setting('buffers.show_emoticons')) {
          var currentWord = this.$refs.input.getCurrentWord();
          var emojiList = this.$state.setting('emojis');

          if (emojiList.hasOwnProperty(currentWord.word)) {
            var emoji = emojiList[currentWord.word];
            var url = this.$state.setting('emojiLocation') + emoji;
            this.$refs.input.setCurrentWord('');
            this.$refs.input.addImg(currentWord.word + ' ', url);
          }
        }
      } else if (event.keyCode === 38) {
        // Up
        if (this.$refs.input.getCaretIdx() > 0) {
          // not at the start of input, allow normal input behaviour
          return;
        }

        event.preventDefault();
        this.historyBack();
      } else if (event.keyCode === 40) {
        // Down
        var end = this.$refs.input.getRawText().replace(/\r?\n/g, '').length;

        if (this.$refs.input.getCaretIdx() < end) {
          // not at the end of input, allow normal input behaviour
          return;
        }

        event.preventDefault();
        this.historyForward();
        this.$nextTick(function () {
          _this2.$refs.input.selectionToEnd();
        });
      } else if (event.keyCode === 9 && !event.shiftKey && !event.altKey && !event.metaKey && !event.ctrlKey) {
        // Tab and no other keys as tab+other is often a keyboard shortcut
        // Tab key was just pressed, start general auto completion
        var _currentWord = this.$refs.input.getCurrentWord();

        var currentToken = _currentWord.word.substr(0, _currentWord.position);

        var inputText = this.$refs.input.getRawText();
        var items = [];

        if (inputText.indexOf('/set') === 0) {
          items = this.buildAutoCompleteItems({
            settings: true
          });
        } else {
          items = this.buildAutoCompleteItems({
            users: true,
            buffers: true
          });
        }

        this.openAutoComplete(items);
        this.autocomplete_filter = currentToken; // Disable filtering so that tabbing cycles through words more like
        // traditional IRC clients.

        this.autocomplete_filtering = false;
        event.preventDefault();
      } else if (meta && event.keyCode === 75) {
        // meta + k
        this.toggleInputTool(_TextStyle.default);
        event.preventDefault();
      } else if (meta && event.keyCode === 66) {
        // meta + b
        this.toggleBold();
        event.preventDefault();
      } else if (meta && event.keyCode === 73) {
        // meta + i
        this.toggleItalic();
        event.preventDefault();
      } else if (meta && event.keyCode === 85) {
        // meta + u
        this.toggleUnderline();
        event.preventDefault();
      }
    },
    inputKeyUp: function inputKeyUp(event) {
      var inputVal = this.$refs.input.getRawText();
      var currentWord = this.$refs.input.getCurrentWord();
      var currentToken = currentWord.word.substr(0, currentWord.position);
      var autocompleteTokens = this.$state.setting('autocompleteTokens');

      if (event.keyCode === 27 && this.autocomplete_open) {
        this.autocomplete_open = false;
      } else if (this.autocomplete_open && currentToken === '') {
        this.autocomplete_open = false;
      } else if (this.autocomplete_open) {
        // @ is a shortcut to open the nicklist autocomplete. It's not part
        // of the nick so strip it out before passing currentToken to the
        // filter.
        if (currentToken[0] === '@') {
          currentToken = currentToken.substr(1);
        }
      } else if (currentToken === '@' && (0, _includes.default)(autocompleteTokens).call(autocompleteTokens, '@')) {
        // Just typed @ so start the nick auto completion
        this.openAutoComplete(this.buildAutoCompleteItems({
          users: true
        }));
        this.autocomplete_filtering = true;
      } else if (inputVal === '/' && (0, _includes.default)(autocompleteTokens).call(autocompleteTokens, '/')) {
        // Just typed / so start the command auto completion
        this.openAutoComplete(this.buildAutoCompleteItems({
          commands: true
        }));
        this.autocomplete_filtering = true;
      } else if (currentToken === '#' && (0, _includes.default)(autocompleteTokens).call(autocompleteTokens, '#')) {
        // Just typed # so start the command auto completion
        this.openAutoComplete(this.buildAutoCompleteItems({
          buffers: true
        }));
        this.autocomplete_filtering = true;
      } else if (event.keyCode === 9 && !event.shiftKey && !event.altKey && !event.metaKey && !event.ctrlKey) {
        // Tab and no other keys as tab+other is often a keyboard shortcut
        event.preventDefault();
      } else if (!event.key.match(/^(Shift|Control|Alt|Enter)/)) {
        if (inputVal[0] === '/') {
          // Don't send typing status for commands
          return;
        }

        if ((0, _trim.default)(inputVal).call(inputVal)) {
          this.startTyping();
        } else {
          this.stopTyping(true);
        }
      }

      if (this.autocomplete_open && this.autocomplete_filtering) {
        this.autocomplete_filter = currentToken;
      }
    },
    submitForm: function submitForm() {
      var rawInput = this.$refs.input.getValue();

      if (!rawInput) {
        if (!this.has_focus && this.keep_focus) {
          // Maybe triggered by the send button on empty input,
          // put focus back into the input
          this.$refs.input.focus();
        }

        return;
      }

      var ircText = this.$refs.input.buildIrcText();
      this.$state.$emit('input.raw', ircText);
      this.historyAdd(rawInput);
      this.$refs.input.reset('', this.keep_focus);
      this.stopTyping(false);
    },
    historyAdd: function historyAdd(rawInput) {
      var _context;

      // Add to history, keeping the history trimmed to the last 50 entries
      this.history.push(rawInput);
      (0, _splice.default)(_context = this.history).call(_context, 0, this.history.length - 50);
      this.history_pos = this.history.length;
    },
    historyBack: function historyBack() {
      var rawText = this.$refs.input.getRawText();
      var rawInput = this.$refs.input.getValue();

      if ((0, _trim.default)(rawText).call(rawText) && this.history_pos === this.history.length) {
        this.historyAdd(rawInput);
        this.history_pos--;
      }

      if (this.history_pos > 0) {
        this.history_pos--;
      }
    },
    historyForward: function historyForward() {
      // Purposely let history_pos go 1 index beyond the history length
      // so that we can detect if we're not currently using a history value
      if (this.history_pos < this.history.length) {
        this.history_pos++;
      }
    },
    focusChanged: function focusChanged(event) {
      this.has_focus = event.type === 'focus';

      if (event.type === 'blur' && event.relatedTarget && event.relatedTarget === this.$refs.sendButton) {
        // new target is the send button, keep focus on reset
        return;
      }

      this.keep_focus = event.type === 'focus';
    },
    openAutoComplete: function openAutoComplete(items) {
      if (this.$state.setting('showAutocomplete')) {
        this.autocomplete_items = items;
        this.autocomplete_open = true;
      }
    },
    buildAutoCompleteItems: function buildAutoCompleteItems(_opts) {
      var opts = _opts || {};
      var list = [];

      if (opts.users) {
        var _context2;

        var userList = (0, _map.default)(_context2 = (0, _values.default)(_lodash.default).call(_lodash.default, this.buffer.users)).call(_context2, function (user) {
          var item = {
            text: user.nick,
            type: 'user'
          };
          return item;
        });

        if (this.buffer.isQuery()) {
          userList.push({
            text: this.buffer.name,
            type: 'user'
          });
        }

        list = (0, _concat.default)(list).call(list, userList);
      }

      if (opts.buffers) {
        var bufferList = [];
        this.network.buffers.forEach(function (buffer) {
          if (buffer.isChannel()) {
            bufferList.push({
              text: buffer.name,
              type: 'buffer'
            });
          }
        });
        list = (0, _concat.default)(list).call(list, bufferList);
      }

      if (opts.commands) {
        var commandList = [];

        _autocompleteCommands.default.forEach(function (command) {
          var _context3;

          // allow descriptions to be translation keys or static strings
          var desc = command.description.indexOf('locale_id_') === 0 ? TextFormatting.t(command.description.substr(10)) : command.description;
          commandList.push({
            text: '/' + command.command,
            description: desc,
            type: 'command',
            // Each alias needs the / command prefix adding
            alias: (0, _map.default)(_context3 = command.alias || []).call(_context3, function (c) {
              return '/' + c;
            })
          });
        });

        list = (0, _concat.default)(list).call(list, commandList);
      }

      if (opts.settings) {
        var out = {};
        var base = [];
        settingTools.buildTree(out, base, this.$state.getSetting('settings'), false);
        settingTools.buildTree(out, base, this.$state.getSetting('user_settings'), true);
        var settingList = [];
        (0, _keys.default)(out).forEach(function (setting) {
          settingList.push({
            text: setting,
            type: 'setting'
          });
        });
        list = (0, _concat.default)(list).call(list, settingList);
      }

      return list;
    },
    startTyping: function startTyping() {
      var _this3 = this;

      if (!this.network.ircClient.network.cap.isEnabled('message-tags')) {
        return;
      }

      if (!this.buffer || !this.buffer.shouldShareTyping()) {
        return;
      }

      if (this.typingTimer) {
        clearTimeout(this.typingTimer);
        this.typingTimer = null;
      }

      this.typingTimer = setTimeout(function () {
        return _this3.stopTyping(true);
      }, 3000);

      if (Date.now() < this.lastTypingTime + 3000) {
        return;
      }

      this.network.ircClient.typing.start(this.buffer.name);
      this.lastTypingTime = Date.now();
    },
    stopTyping: function stopTyping(sendStop) {
      var _context4;

      if (!this.network.ircClient.network.cap.isEnabled('message-tags')) {
        return;
      }

      if (!this.buffer || !this.buffer.shouldShareTyping()) {
        return;
      }

      if (this.typingTimer) {
        clearTimeout(this.typingTimer);
        this.typingTimer = null;
        this.lastTypingTime = 0;
      }

      (0, _trim.default)(_context4 = this.$refs.input.getRawText()).call(_context4) ? this.network.ircClient.typing.pause(this.buffer.name) : this.network.ircClient.typing.stop(this.buffer.name, sendStop);
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["ControlInput"]
window._kiwi_exports.components.ControlInput = exports.default ? exports.default : exports;
